
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Paginate from "@/components/Paginate.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  components: {
    ErrorMessage,
    Field,
    Form,
    Paginate,
  },
  setup() {
    const title = "Kader";
    const name = "Kader";
    setCurrentPageTitle(title);
    setCurrentPageTitle(title);
    const store = useStore();
    const path = window.location.origin + "/";
    const page = computed(() => store.state.KaderCategoryModule.page);
    const limit = computed(() => store.state.KaderCategoryModule.limit);
    const totalPage = computed(() => store.state.PartiesModule.totalPage);
    const baseUrl = ApiService.vueInstance.axios.defaults.baseURL;

    // call data required
    store.dispatch("All" + name);
    store.dispatch("AllKaderCategory");
    store.dispatch("AllParties");
    store.dispatch("AllTerritory");

    const data = computed(() => store.state.KaderModule.all);
    const party = computed(() => store.state.PartiesModule.all);
    const territory = computed(() => store.state.TerritoryModule.all);
    const role = computed(() => store.state.KaderCategoryModule.all);

    const detail = ref({
      newFoto: "",
      profile: "",
      data: {},
    });

    store.commit("SET_ACTIVE_MENU", "admin.politic.kader.list");

    const inputButton = ref<HTMLElement | null>(null);
    const editButton = ref<HTMLElement | null>(null);

    const foto = ref(path + "other/default/blank.png");
    const created = ref({
      newFoto: "",
      profile: "",
    });

    const form = Yup.object().shape({
      name: Yup.string().required().label("Nama"),
      image: Yup.string().required().label("Profile"),
      description: Yup.string().required().label("Deskripsi"),
      cadre_role_id: Yup.string().required().label("Kategori"),
      territory_id: Yup.string().required().label("Lokasi"),
      party_id: Yup.string().required().label("Partai"),
    });

    const formEdit = Yup.object().shape({
      name: Yup.string().required().label("Nama"),
      image: Yup.string().required().label("Profile"),
      description: Yup.string().required().label("Deskripsi"),
      cadre_role_id: Yup.string().required().label("Kategori"),
      territory_id: Yup.string().required().label("Lokasi"),
      party_id: Yup.string().required().label("Partai"),
    });

    const getDetail = (item) => {
      // method 1
      // store.dispatch("detail" + name.toUpperCase(), item.id);
      // method 2
      detail.value.newFoto = baseUrl + item.profile;
      detail.value.data = item.cadre;

      setTimeout(() => {
        (document.getElementById("open-modal-detail") as HTMLElement).click();
      }, 300);
    };

    //Form submit function
    const FormSubmit = () => {
      if (inputButton.value) {
        // Activate indicator
        inputButton.value.setAttribute("data-kt-indicator", "on");
      }

      const val = document.getElementById("form-input") as HTMLFormElement;
      const data = new FormData(val);

      // console.log(data);

      // Dummy delay
      setTimeout(() => {
        // Send request
        store
          .dispatch("store" + name, data)
          .then(() => {
            Swal.fire({
              text: store.state.ProfilModule.msg,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              // Go to page after successfully
              // goBack();
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.state.ProfilModule.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        inputButton.value?.removeAttribute("data-kt-indicator");
      }, 1000);
    };
    const FormUpdate = () => {
      if (editButton.value) {
        // Activate indicator
        editButton.value.setAttribute("data-kt-indicator", "on");
      }

      const val = document.getElementById("form-edit") as HTMLFormElement;
      const data = new FormData(val);

      // console.log(data);

      // Dummy delay
      setTimeout(() => {
        // Send request
        store
          .dispatch("store" + name, data)
          .then(() => {
            Swal.fire({
              text: store.state.ProfilModule.msg,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              // Go to page after successfully
              // goBack();
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.state.ProfilModule.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        editButton.value?.removeAttribute("data-kt-indicator");
      }, 1000);
    };
    const changeLimit = (el) => {
      store.commit("SET_LIMIT_" + name.toUpperCase(), el.target.value);
      store.dispatch("All" + name);
    };
    const getRows = (val) => {
      store.dispatch("All" + name).then(() => {
        store.commit("SET_PAGE_" + name.toUpperCase(), val);
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    };
    const destroyData = (item) => {
      Swal.fire({
        html: `<b>${title} ${item.name}</b>, Yakin dihapus?`,
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Hapus!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
        showLoaderOnConfirm: true,
        backdrop: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch("destroy" + name, item.id)
            .then(() => {
              Swal.fire("Terhapus!", `${title} berhasil dihapus.`, "success");
            })
            .catch(() => {
              Swal.fire(store.state.ProfilModule.error, "success");
            });
        }
      });
    };
    const resetData = () => {
      changeLimit({ target: { value: 10 } });
      getRows(1);
      store.dispatch("All" + name);
    };
    const changeFoto = (el) => {
      const category = el.target.id;

      if (category == "input-preview") {
        (document.getElementById("input-foto") as HTMLElement).click();
      } else if (category == "update-preview") {
        (document.getElementById("update-foto") as HTMLElement).click();
      }
    };
    const preFoto = (el) => {
      const category = el.target.id;
      if (el.target.value) {
        const file = el.target.files[0];
        if (category == "input-foto") {
          created.value.newFoto = URL.createObjectURL(file);
          created.value.profile = el.target.files[0].name;
        } else if (category == "update-foto") {
          detail.value.newFoto = URL.createObjectURL(file);
          detail.value.profile = el.target.files[0].name;
        }
      } else {
        if (category == "input-foto") {
          created.value.newFoto = "";
          created.value.profile = "";
        } else if (category == "update-foto") {
          detail.value.newFoto = "";
          detail.value.profile = "";
        }
      }
    };

    return {
      title,
      page,
      limit,
      totalPage,
      data,
      party,
      territory,
      role,
      detail,
      path,
      foto,
      created,
      form,
      formEdit,
      getDetail,
      FormSubmit,
      FormUpdate,
      changeLimit,
      getRows,
      destroyData,
      resetData,
      changeFoto,
      preFoto,
    };
  },
});
